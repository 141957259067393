<template>
  <div class="px-2">
    <b-row class="pt-2">
      <b-col cols="12">
        <b-card no-body>
          <b-card-header>
            <div class="d-flex align-items-center">
              <feather-icon class="mr-1" icon="FilterIcon"/>
              {{ $t("Filters") }}
            </div>
          </b-card-header>
          <b-card-body>
            <b-row>
              <b-col cols="3">
                <p style="margin-bottom: 0.5rem">{{ $t("Choose a date") }}</p>
                <flat-pickr
                    v-model="filterValues.time"
                    :config="{
										wrap: true,
										time_24hr: true,
										mode: 'range',
										locale: $i18n.locale === 'cn' ? 'zh' : 'en',
									}"
                    class="form-control"
                />
              </b-col>
              <b-col cols="3">
                <p style="margin-bottom: 0.5rem">
                  {{ $t("Alarm Type") }}
                </p>
                <v-select v-model="filterValues.type" :options="['设备报警','消防报警','安防报警']"
                          :placeholder="$t('No filter')"
                          class="w-100" :clearable="true"/>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>

      <!-- Table Container Card -->
      <b-col cols="12">
        <b-card no-body>
          <div class="m-2 mb-0">
            <!-- Table Top -->
            <b-row>
              <b-col class="d-flex align-items-center justify-content-center justify-content-sm-start" cols="12" sm="6">
								<span class="text-muted">
									{{
                    `               筛选
                                    ${total}
                                    ${$t('of')}
                                    ${alarmList.length}
                                    ${$t("Entries")}
                                    `
                  }}
								</span>
              </b-col>
            </b-row>
          </div>

          <b-table
              ref="refAlarmListTable"
              :busy="isBusy"
              :empty-text="$t('No Data')"
              :fields="tableColumns"
              :items="tableItems"
              bordered
              class="text-center position-relative"
              hover
              primary-key="alarm_id"
              responsive
              show-empty
              small
              striped
              style="height: calc(100vh - 26rem)"
          >
            <template v-slot:table-busy>
              <div class="text-center my-2">
                <b-spinner class="align-middle mr-1"></b-spinner>
                <strong>{{ $t("Loading") }}...</strong>
              </div>
            </template>
            <!-- Column: Index -->
            <template #cell(index)="data">
              <div class="text-nowrap">
								<span class="align-text-top text-capitalize">
									{{ data.index + 1 }}
								</span>
              </div>
            </template>
            <template #cell(actions)="data">
              <b-button v-b-modal:detail-alarms-modal class="btn-icon" size="sm" variant="warning">
                <feather-icon icon="MoreHorizontalIcon"/>
              </b-button>
            </template>
          </b-table>

        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import vSelect from "vue-select";
import JsonExcel from "vue-json-excel";
import flatPickr from "vue-flatpickr-component";
import zh from "flatpickr/dist/l10n/zh.js";
import Ripple from "vue-ripple-directive";
import {computed, onMounted, reactive, toRefs, watch} from "@vue/composition-api";
import {showToast} from "@/libs/utils/showToast";
import {getAlarms} from "@/api/jsf35";
import PageTitle from "@/views/layout/PageTitle";
import {routerParams} from "@/libs/utils/routerParams";
import {momentFmt, momentJs} from "@/libs/utils/moment";

export default {
  directives: {
    Ripple,
  },
  components: {
    PageTitle,
    vSelect,
    flatPickr,
    downloadExcel: JsonExcel,
  },
  props: ["hide-title"],
  setup() {
    const event = reactive({
      project_id: computed(() => {
        return routerParams("project_id");
      }),
      filterOptions: {},
      filterValues: {
        type: null,
        time: momentJs().format(),
      },
      isEditAlarmSidebarActive: false,
      refAlarmListTable: null,
      perPage: 20,
      searchQuery: "",
      tableColumns: [
        {
          label: "#",
          key: "index",
        },
        {
          label: "报警名称",
          key: "name",
        },
        {
          label: "报警类型",
          key: "type",
        },
        {
          label: "报警次数",
          key: "num",
        },
        {
          label: "最新报警值",
          key: "cur_warning_val",
        },
        {
          label: "最新报警时间",
          key: "time",
        },
        // {
        //   label: "操作",
        //   key: "actions",
        // },
      ],
      tableItems: [],
      alarmList: [],
      total: 0,
      isBusy: false,
    });

    watch([() => event.filterValues.time, () => event.filterValues.type], (filter) => {
      let time = filter[0]
      let type = filter[1]
      let list = event.alarmList
      let time_arr = time.split(' ')
      if (time_arr.length === 3) {
        let time_1 = time_arr[0] + ' 00:00:00'
        let time_2 = time_arr[2] + ' 23:59:59'
        time_1 = momentJs(time_1).valueOf()
        time_2 = momentJs(time_2).valueOf()
        list = list.filter(item => item.time && momentJs(item.time) > time_1 && momentJs(item.time) < time_2)
      }
      if (type) {
        list = list.filter(item => item.type === type)
      }
      event.tableItems = list
      event.total = list.length
    })


    const fetchAlarms = (ctx, callback) => {
      event.isBusy = true;
      let params = {
        project_id: event.project_id,
      };
      getAlarms(params)
          .then((response) => {
            let list = [];
            let total = 0
            response.data.map(data => {
              list = list.concat(data.list)
              total += data.list.length
            })
            event.alarmList = list
            event.tableItems = list
            event.total = total;
            event.isBusy = false;
          })
          .catch((error) => {
            event.isBusy = false;
            showToast(error);
          });
    };

    onMounted(() => {
      fetchAlarms()
    })

    return {
      ...toRefs(event),
      zh,
      momentFmt,
      momentJs,
    };
  },
};
</script>

<style lang="scss">
/* .per-page-selector {
width: 90px;
}

.b-form-btn-label-control.dropdown.b-form-datepicker.form-control
label.form-control {
padding: 0.3rem 1rem;
} */
</style>

<style lang="scss">
//noinspection CssUnknownTarget
@import "@/@core/scss/vue/libs/vue-flatpicker";
</style>
